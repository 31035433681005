<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='卡类型' prop='type' :label-width="formLabelWidth">
                <el-select v-model="form.type" placeholder="请选择">
                    <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='卡号' prop='cardno' :label-width="formLabelWidth">
              <el-input placeholder='请输入卡号' clearable autocomplete="off" v-model='form.cardno' size="small"  />
            </el-form-item>
            <el-form-item label='开卡平台' prop='cardplat' :label-width="formLabelWidth">
              <el-input placeholder='请输入开卡平台' clearable autocomplete="off" v-model='form.cardplat' size="small"  />
            </el-form-item>
            <el-form-item label='银行名称' prop='content' :label-width="formLabelWidth">
              <el-input placeholder='请输入银行名称' clearable autocomplete="off" v-model='form.content' size="small"  />
            </el-form-item>
            <el-form-item label='卡片状态' prop='status' :label-width="formLabelWidth">
              <el-input placeholder='请输入卡片状态' clearable autocomplete="off" v-model='form.status' size="small"  />
            </el-form-item>
            <el-form-item label='持有人id' prop='userid' :label-width="formLabelWidth">
              <el-input placeholder='请输入持有人id' clearable autocomplete="off" v-model='form.userid' size="small"  />
            </el-form-item>
            <el-form-item label='持有人' prop='username' :label-width="formLabelWidth">
              <el-input placeholder='请输入持有人' clearable autocomplete="off" v-model='form.username' size="small"  />
            </el-form-item>
            <el-form-item label='到期时间' prop='datetime' :label-width="formLabelWidth">
                <el-date-picker
                        v-model="form.datetime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
<!--            <el-form-item label='商品类型id' prop='typeid' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入商品类型id' clearable autocomplete="off" v-model='form.typeid' size="small"  />-->
<!--            </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertCardinfo,updateCardinfo,deleteCardinfo,bacthDelCardinfo } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const typeOptions=[
          {
              value:"1",
              label:"信用卡"
          },
          {
              value:"2",
              label:"礼品卡"
          },
      ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'type',label: '卡类型'},
          {prop: 'cardno',label: '卡号'},
          {prop: 'cardplat',label: '开卡平台'},
          {prop: 'content',label: '银行名称'},
          {prop: 'status',label: '卡片状态'},
          {prop: 'userid',label: '持有人id'},
          {prop: 'username',label: '持有人'},
          {prop: 'datetime',label: '到期时间'},
          {prop: 'typeid',label: '商品类型id'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
            type: 'input',
            id: 'type',
            label: '卡类型',
            el: {placeholder: '请输入卡类型', clearable: true},
          },
          {
            type: 'input',
            id: 'cardno',
            label: '卡号',
            el: {placeholder: '请输入卡号', clearable: true},
          },
          {
            type: 'input',
            id: 'cardplat',
            label: '开卡平台',
            el: {placeholder: '请输入开卡平台', clearable: true},
          },
          {
            type: 'input',
            id: 'content',
            label: '银行名称',
            el: {placeholder: '请输入银行名称', clearable: true},
          },
          {
            type: 'input',
            id: 'status',
            label: '卡片状态',
            el: {placeholder: '请输入卡片状态', clearable: true},
          },
          {
            type: 'input',
            id: 'userid',
            label: '持有人id',
            el: {placeholder: '请输入持有人id', clearable: true},
          },
          {
            type: 'input',
            id: 'username',
            label: '持有人',
            el: {placeholder: '请输入持有人', clearable: true},
          },
      ]
      return {
        columns,
        typeOptions,
        userinfo:{},
        tableConfig: {
          searchForm,
          url: 'cardinfo/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('cardinfo:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('cardinfo:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('cardinfo:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('cardinfo:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            type :[
              {required: true, message: '请输入卡类型', trigger: 'blur'},],
            cardno :[
              {required: true, message: '请输入卡号', trigger: 'blur'},],
            cardplat :[
              {required: true, message: '请输入开卡平台', trigger: 'blur'},],
            content :[
              {required: true, message: '请输入银行名称', trigger: 'blur'},],
            status :[
              {required: true, message: '请输入卡片状态', trigger: 'blur'},],
            userid :[
              {required: true, message: '请输入持有人id', trigger: 'blur'},],
            username :[
              {required: true, message: '请输入持有人', trigger: 'blur'},],
            datetime :[
              {required: true, message: '请输入到期时间', trigger: 'blur'},],
            typeid :[
              {required: true, message: '请输入商品类型id', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          type : "", // 卡类型
          cardno : "", // 卡号
          cardplat : "", // 开卡平台
          content : "", // 银行名称
          status : "", // 卡片状态
          userid : "", // 持有人id
          username : "", // 持有人
          datetime : "", // 到期时间
          typeid : "", // 商品类型id
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteCardinfo(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelCardinfo(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertCardinfo(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateCardinfo(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('cardinfo:update')&&!this.hasRole('cardinfo:delete')){
          this.tableConfig.hasOperation=false
        }
      }
    }
  }
</script>

<style scoped>

</style>
